
html {
	background: $brand-color;
	font-size: 100%;
}
@media #{$large-desktop} {
	html {
		font-size: 120%;
	}
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	font-family: "Helvetica Neue", "Helvetica", "Arial";
}

a {
	color: $accent-color;
	text-decoration: underline;
}

a:hover {
	text-decoration: underline;
}

img {
	width: 100%;
}

h1 strong, h2 strong {
	font-weight: 700;
	position: relative;
	padding: .25em 0 .5em .75em;
	border-left: 6px solid $main-color;
}

h1 {
	font-weight: 300;
	font-size: 1.8rem;
	margin: 0;
	position: relative;
	padding: .25rem 0 .5rem .75rem;
	border-left: 6px solid $main-color;
}

h2 {
	font-weight: 700;
	font-size: 1.5rem;
	margin: 0 0 30px 0;
	position: relative;
	padding: .15rem 0 .25rem .75rem;
	color: $logo-color;
	border-left: 12px solid $brand-color;
}

h2::after {
	position: absolute;
	left: 0;
	bottom: 0;
	content: '';
	width: 100%;
	height: 0;
	border-bottom: 3px solid #ccc;
}

h3 {
	font-weight: 700;
	font-size: 1.3rem;
	margin: 0 0 30px 0;
	position: relative;
	padding: .15rem 0 .25rem .75rem;
	color: $logo-color;
	border-left: 10px solid $main-color;
}

h3::after {
	position: absolute;
	left: 0;
	bottom: 0;
	content: '';
	width: 100%;
	height: 0;
	border-bottom: 3px solid #ccc;
}

h4 {
	font-weight: 400;
	font-size: 1.2rem;
	margin: 0 0 30px 0;
	position: relative;
	padding: .15rem 0 .25rem .75rem;
	color: $logo-color;
	border-left: 8px solid $dark-sub-color;
}

h4::after {
	position: absolute;
	left: 0;
	bottom: 0;
	content: '';
	width: 80%;
	height: 0;
	border-bottom: 3px solid #ccc;
}

p, address {
	font-size: 1.0rem;
	color: #000000;
	margin-bottom: 20px;
	font-weight: 300;
	line-height: 1.4rem;
	text-align: justify;
    text-justify: inter-word;
}

li {
	font-size: 0.95rem;
}

.container, .text-container {
	margin: 0 auto;
	position: relative;
	padding: 0 20px;
}

.text-container {
	max-width: 750px;
}

.flex {
	@include flexbox;
	align-items: center;
	flex-direction: row;

	.text {
		-webkit-flex: 1;
		flex: 1;
		padding: 0 0;
	}
}

@media #{$desktop} {
	.flex_twol {
		@include flexbox;
		align-items: center;
		flex-direction: row;
		.text {
			-webkit-flex: 1;
			flex: 1;
			padding: 0 20px 0 0;
		}
	}
	.flex_twor {
		@include flexbox;
		align-items: center;
		flex-direction: row-reverse;

		.text {
			-webkit-flex: 1;
			flex: 1;
			padding: 0 0 0 20px;
		}
	}
}

.container {
	max-width: 1140px;
	&.max-container {
		max-width: 100%;
		padding: 0;
	}
}

.container-border {
	border: solid 3px $logo-color;
	background: $sub-color;
	margin: 5px 10px;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 50px;
}

header {
	color: #fff;
	padding: 20px 0;
	background: $brand-color; /* Old browsers */
	background: $brand-color 0%;

	a {
		color: #fff;
		text-decoration: none;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}

	.company-name {
		font-size: 2.2em;
		line-height: 0;
		a {
			display: inline-block;
		}

		img {
			display: block;
			width: auto;
		}
	}
}

.content {
	background: $base-color;
	padding: 1px 0 0 0;
	padding-bottom: 60px;
	position: relative;
}

section {
	padding: 40px 0;
}

section + section {
	padding: 0px 0;
}

.subtext {
	margin-top: 10px;
}

.cta {
    width: 200px;
	margin: 10px auto;
}

@media all and #{$desktop} {
	div.image {
		width: 50%;
		height: 400px;
		img {
			height: 400px;
			object-fit: contain;
		}
	}
	div.image_short {
		width: 50%;
		height: 250px;
		img {
			height: 250px;
			object-fit: contain;
		}
	}
	div.image_small {
		width: 30%;
		height: 300px;
		img {
			height: 300px;
			object-fit: contain;
		}
	}
	div.image_tiny {
		width: 25%;
		height: 250px;
		img {
			height: 250px;
			object-fit: contain;
		}
	}
	div.image_petit {
		width: 20%;
		height: 200px;
		img {
			height: 200px;
			object-fit: contain;
		}
	}
	div.c {
		width: 20%;
		height: 200px;
		img {
			height: 200px;
			object-fit: contain;
		}
	}
}

@media not all and #{$desktop} {
	div.image {
		position: relative;
		width: 100%;
		img {
			width: 70%;
			display: block;
			margin: auto;
		}
	}
	div.image_small {
		position: relative;
		width: 100%;
		img {
			width: 50%;
			display: block;
			margin: auto;
		}
	}
	div.image_tiny {
		position: relative;
		width: 100%;
		img {
			width: 40%;
			display: block;
			margin: auto;
		}
	}
	div.image_petit {
		position: relative;
		width: 100%;
		img {
			width: 35%;
			display: block;
			margin: auto;
		}
	}
}

div.wideimage {
	position: relative;
	width: 100%;
	padding-bottom: 60%;
	float: center;
	height: 0;
	img {
		position: absolute;
		display: block;
		margin: auto;
		width: auto;
		height: 100%;
		text-align: center;
		left: 0;
		right: 0;
	}
}

.page {
	margin-bottom: 0;
	padding-bottom: 0px;
}

.center-text {
	text-align: center;
}

div.checkmark {
  position:relative;
  display:inline-block;
  &:after{
    content: '';
    display: block;
    width: 0.4rem;
    height: 0.8rem;
    border: solid #090;
    border-width: 0 0.25rem 0.25rem 0;
    transform: rotate(45deg);
  }
}

.crossmark {
  position: relative;
  display:inline-block;
  right: 0.5rem;
  top: -0.5rem;
  width: 0.5rem;
  height: 0.5rem;
}

.crossmark:before, .crossmark:after {
  position: absolute;
  left: 0.6rem;
  content: ' ';
  height: 1.3rem;
  width: 0.25rem;
  background-color: #f00;
}

.crossmark:before {
  transform: rotate(45deg);
}

.crossmark:after {
  transform: rotate(-45deg);
}
div.button {
	a {
		color: $logo-color;
		text-decoration: none;
		background: $brand-color;
		border-radius: 3px;
		border: 1px solid $logo-color;
		transition: .2s ease-in-out;
		svg {
			fill: $logo-color;
			margin-right: -5px;
			transition: fill 0.2s ease;
			vertical-align: middle;
			position: relative;
			top: -2px;
			left: -10px;
			width: 22px;
			height: 22px;
		}
		&:hover svg {
			fill: #fff;
		}
	}
	a:hover {
		border: 1px solid $logo-color;
		background: $base-color;
		cursor: pointer;
	}
}

div.button.alt a {
	background: rgba(255,255,255,0.15);
	border-radius: 3px;
	padding: 10px;
	border: 1px solid $logo-color;
	display: block
}

div.button.alt a:hover {
	background: $logo-color;
	color: #fff;
}

button { font-family: inherit; font-size: inherit; }

div.fancylist dl {
	margin: 0;
	padding: .5em;
}

div.fancylist dt {
	margin: 0;
	padding:.5em;
	font-weight: bold;
	background-color: $sub-color;
	border-left: 6px solid $dark-sub-color;
}

div.fancylist dd {
	margin: 0;
	padding: 0;
	padding-top: .5em;
	padding-bottom: .5em;
	padding-left: 3.0em;
	clear: both;
	margin-top: .5em;
	margin-bottom: .5em;
	clear: both;
}


div.list dl {
	margin: 0;
	padding: .2em;
}

div.list dt {
	margin: 0;
	padding:.2em;
	font-weight: bold;
	background-color: $sub-color;
	border-left: 6px solid $dark-sub-color;
}

div.list dd {
	margin: 0;
	padding: 0;
	padding-top: .2em;
	padding-bottom: .3em;
	padding-left: 3.0em;
	clear: both;
	margin-top: .1em;
	margin-bottom: .3em;
	clear: both;
}
