
.subtitle {
	margin-top: 0px;
	text-align: center;
	text-justify: none;
	font-size: 1.3rem;
}

.hero {
	color: #ffffff;
	text-align: center;
	background: $header-color;
	padding: 20px;

	h1 {
    	padding: 20px 0 20px 0;
    	margin-left: auto;
	    margin-right: auto;
	}
	p {
		color: #fff;
	}
}
