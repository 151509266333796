$brand-color: #0b3868;
$base-color: #f0f0f0;
$main-color: #1b8ec9;
$header-color: #dddddd;
$dark-sub-color: #555555;
$sub-color: #f5f5f5;
$accent-color: #92140c;
$copyright-color: #222527;
$logo-color: #383635;

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";
$large-desktop: "(min-width: 1000px)";
